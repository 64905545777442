import React from "react";

export default function SectionHeading({ title, tag }) {
  return (
    <div className="flex flex-col">
      {tag && (
        <p className="md:text-base text-darkGrey text-sm text-center capitalize">
          {tag}
        </p>
      )}
      <h2 className="font-medium capitalize tracking-[3px] relative text-center sectionHeading after:absolute after:content-[''] lg:after:w-[100px] after:w-[60px] after:h-[2px] after:bg-darkBrown after:top-full after:left-1/2 after:transform after:-translate-x-1/2 after:mt-2">
        {title}
      </h2>
    </div>
  );
}
