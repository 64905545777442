import React from "react";
import cn from "classnames";
export default function Section({
  children,
  className,
  variant = "normal",
  id,
}) {
  return (
    <div
      id={id}
      className={cn(
        ` w-full h-auto flex flex-col z-10 relative`,
        {
          "lg:py-20 py-16 lg:px-[5vw] md:px-8 px-4": variant === "normal",
          "py-16 lg:px-[5vw] md:px-8 px-4": variant === "medium",
          "py-10 px-4 md:px-8 lg:px-[5vw]": variant === "flat",
        },
        className
      )}
    >
      <div className={`w-full flex flex-col`}>{children}</div>
    </div>
  );
}
