import React from "react";
import Image from "next/image";
import cn from "classnames";
// import KnowMore from "./know-more";
import { m } from "framer-motion";
import Button from "./button";
export default function BannerCard({
  banner,
  className,
  path,
  variant = "normal",
  isActive = true,
  title,
}) {
  return (
    <div className={cn("mx-auto w-full", className)}>
      <m.div
        // initial={{ scale: 1.1 }}
        // animate={{ scale: 1 }}
        // transition={{ duration: 8, ease: "easeOut" }}
        className={cn("flex w-full  relative overflow-hidden", {
          "lg:h-screen md:h-[70vh] h-screen": variant == "normal",
          "lg:h-[80vh] md:h-[50vh] h-[350px]": variant == "small",
        })}
      >
        <Image
          src={banner}
          fill
          alt={title ?? "banner image"}
          priority={isActive ? true : false}
          className="object-cover object-center w-full h-full"
          sizes="(max-width: 768px) 768px, (max-width: 1200px) 1200, 1400px"
        />

        {variant === "normal" && (
          <div className="bg-black/20 absolute top-0 left-0 w-full h-full flex flex-col gap-6 items-center justify-end md:pb-20 pb-32 lg:px-24 md:px-12 px-6 pt-12">
            <m.h1
              // initial={{ opacity: 0, y: -40 }}
              // whileInView={{ opacity: 1, y: 0 }}
              // transition={{
              //   duration: 0.5,
              // }}
              className="text-white leading-tight text-center"
              key={banner?.id}
            >
              {title}
            </m.h1>
            <Button path={path} variant="light">
              Shop Now
            </Button>
          </div>
        )}
        {variant === "small" && (
          <div className="bg-black/50 absolute top-0 left-0 w-full h-full flex flex-col items-start justify-end lg:px-24 md:px-12 px-4 lg:py-12 py-6">
            <div className="w-full flex flex-col items-start justify-end gap-4 ">
              {/* <m.h1
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 100, y: 0 }}
                transition={{ duration: 0.3, ease: "linear" }}
                className="h-fit lg:text-[50px] md:text-[40px] text-2xl w-fit bg-black rounded-md bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-55 text-white px-4 py-2 font-medium"
              >
                {title}
              </m.h1> */}
            </div>
          </div>
        )}
      </m.div>
    </div>
  );
}
