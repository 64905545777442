import React from "react";
import { NextSeo } from "next-seo";
export default function Seo({
  slug = "",
  metaTitle = "Rose Bowl | Shop Now",
  metaDescription = "Explore Our Glassworks. Browse our exclusive range of handcrafted glass platters and T-lights to illuminate your spaces.",
  metaImage = "https://rosebowl.in/images/rosebowl-logo-512x512.webp",
}) {
  return (
    <NextSeo
      title={metaTitle}
      description={metaDescription}
      url={`https://rosebowl.in/${slug}`}
      canonical={`https://rosebowl.in/${slug}`}
      openGraph={{
        type: "website",
        locale: "en_IE",
        site_name: "Rose Bowl",
        url: `https://rosebowl.in/${slug}`,
        title: metaTitle,
        description: metaDescription,
        images: [
          {
            url: metaImage,
            width: 140,
            height: 140,
            alt: "Rose Bowl",
          },
        ],
      }}
    />
  );
}
