import React from "react";
import Image from "next/image";
import { m } from "framer-motion";
import Banner from "@/components/pages/home/banner";
import About from "@/components/pages/home/about";
import ProductGrid from "@/components/pages/product.js/product-grid";
import { sanitize } from "@/framework/utils/sanitize";
import { QUERY_ALL_PRODUCTS_BY_COLLECTION } from "@/framework/queries/products";
import { getUrqlClient } from "@/lib/urql-client";
// import { useQuery } from "urql";
import SectionHeading from "@/components/ui/carousel/section-heading";
import Section from "@/components/ui/section";
import Seo from "@/utils/seo";
export default function Home({ lifestyle, homeDecor }) {
  // const [{ data, fetching: loading, error }] = useQuery({
  //   query: QUERY_ALL_PRODUCTS_BY_COLLECTION,
  //   variables: { slug: { eq: "lifestyle" } },
  //   requestPolicy: "network-only",
  // });

  return (
    <>
      <Seo />
      <Banner />
      <About />
      <Section className="bg-beige" variant="flat">
        <SectionHeading title="Serve-in-Style" tag="home" />
        <ProductGrid products={lifestyle} />
      </Section>
      <Section className="bg-darkBeige" variant="flat">
        <SectionHeading title="Home Decor" tag="lifestyle" />
        <ProductGrid products={homeDecor} />
      </Section>
    </>
  );
}
export async function getStaticProps() {
  let props = {};
  const client = await getUrqlClient();
  try {
    const { data } = await client.query(QUERY_ALL_PRODUCTS_BY_COLLECTION, {
      slug: { eq: "lifestyle" },
    });
    const homeDecor = await client.query(QUERY_ALL_PRODUCTS_BY_COLLECTION, {
      slug: { eq: "home-decor" },
    });

    function isObjectEmptyOrNull(obj) {
      if (obj === null || obj === undefined) {
        return true;
      }
      if (Object.keys(obj).length === 0) {
        return true;
      }
      return false;
    }

    if (isObjectEmptyOrNull(data?.collections?.data[0])) {
      return {
        notFound: true,
      };
    }
    props = {
      lifestyle: data?.collections?.data[0]?.attributes?.products?.data?.map(
        (row) => sanitize(row)
      ),
      homeDecor:
        homeDecor?.data?.collections?.data[0]?.attributes?.products?.data?.map(
          (row) => sanitize(row)
        ),
    };
  } catch (error) {
    console.log(error);
  }
  return {
    props,
    revalidate: 10,
  };
}
