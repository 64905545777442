import React from "react";
import Link from "next/link";
import cn from "classnames";

export default function Button({
  children,
  variant = "dark",
  external = false,
  path,
}) {
  return (
    <Link
      href={path ?? "#"}
      className={cn(
        "w-fit text-center font-medium min-w-60 inline-block rounded-full relative z-[10] overflow-hidden md:text-base text-sm py-2 px-4 transition-all duration-[800ms] ease-in-out",
        "after:absolute after:inset-0 after:top-full after:left-0 after:right-0 after:bottom-[-100%] after:transition-all after:duration-[800ms] after:ease-in-out after:z-[-1]",
        {
          "bg-beige text-darkGrey hover:text-white after:bg-darkGrey":
            variant === "light",
          "bg-[#3c322d] text-beige hover:text-[#3c322d] after:bg-beige":
            variant === "dark",
        },
        "hover:after:top-0 hover:after:bottom-0"
      )}
      target={external ? "_blank" : "_self"}
    >
      {children}
    </Link>
  );
}
