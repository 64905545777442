import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "next/image";
import ChevronDown from "@/icons/chevron-down";
import LongArrowRight from "@/icons/long-arrow-right";
export default function Slick({
  children,
  slidesToScroll = 1,
  slidesToShow = 3,
  dots = false,
  infinite = true,
  responsive,
  navigation = true,
  fade = false,
  autoplay = true,
  onSlideChange,
  currentSlide,
  ...props
}) {
  const navWrapper =
    "shrink-0 w-8 h-8 rounded-full bg-darkGrey text-white flex items-center justify-center hover:bg-darkBeige hover:text-darkGrey transition-all duration-300 ease";

  const NextButton = ({ onClick }) => {
    return (
      <button
        // ref={nextRef}
        onClick={onClick}
        className={navWrapper}
        aria-label="next-button"
      >
        <LongArrowRight className="w-3" />
        {/* <IoIosArrowForward /> */}
      </button>
    );
  };
  const PrevButton = ({ onClick }) => {
    return (
      <button
        // ref={prevRef}
        className={navWrapper}
        aria-label="prev-button"
        onClick={onClick}
      >
        <LongArrowRight className="w-3 rotate-180" />
      </button>
    );
  };
  const [slideIndex, setSlideIndex] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);
  let sliderRef = useRef(null);

  const handleBeforeChange = (current, next) => {
    setActiveSlide(next); // Update active slide
    if (onSlideChange) onSlideChange(next);
  };
  useEffect(() => {
    setActiveSlide(currentSlide);
    console.log("current", currentSlide);
    sliderRef.slickGoTo(currentSlide);
  }, [currentSlide]);

  var settings = {
    dots: dots,
    infinite: infinite,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    nextArrow: navigation ? <NextButton /> : false,
    prevArrow: navigation ? <PrevButton /> : false,
    responsive: responsive,
    // lazyLoad: true,
    swipeToSlide: true,
    pauseOnHover: false,
    fade: fade,
    autoplay: autoplay,
    autoplaySpeed: 6000,
    draggable: true,
    accessibility: true,
    beforeChange: (current, next) => {
      setSlideIndex(next);
      if (onSlideChange) onSlideChange(next); // Call the callback here
    },
    beforeChange: handleBeforeChange,
    ...props,
    // cssEase: "linear",
  };

  return (
    <div className="w-full">
      <Slider
        ref={(slider) => {
          sliderRef = slider;
        }}
        {...settings}
      >
        {children}
      </Slider>
    </div>
  );
}
