import { useState } from "react";
import BannerCard from "@/components/ui/banner-card";
import Slick from "@/components/ui/carousel/slick";
const Banner = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const data = [
    {
      id: 1,
      title: "Tulip Mocha",
      image: "/images/banner/brown tulip set of 2.webp",
      path: "/products/tulip-mocha",
    },
    {
      id: 2,
      title: "Mini Shovel Platter",
      image: "/images/banner/shovel-white.webp",
      path: "/products/mini-shovel-platter-white-gold",
    },
    // {
    //   id: 3,
    //   title: "Lazy Susan Round Platter- Black Gold",
    //   image:
    //     "https://d2tsdx3wkbt2fg.cloudfront.net/Lazy_Susan_Round_Platter_Black_Gold_58d8306665.webp",
    //   path: "/products/lazy-susan-round-platter-black-gold-large",
    // },
    {
      id: 4,
      title: "Swirl Black Stallion",
      image: "/images/banner/Swirl With candle lit.webp",
      path: "/products/swirl-black-stallion",
    },
  ];

  return (
    <div className="homeBanner relative mb-0 mx-auto overflow-hidden bg-white w-full">
      <Slick
        dots={true}
        navigation={false}
        slidesToScroll={1}
        slidesToShow={1}
        fade={false}
        onSlideChange={setCurrentSlide}
        autoplay={true}
        dotsClass={"slick-dots slick-thumb"}
        appendDots={(dots) => <ul>{dots}</ul>}
        customPaging={(i) => (
          <div
            className={`ft-slick__dots--custom ${
              currentSlide === i ? "active" : ""
            }`}
          >
            <div className="loading"></div>
          </div>
        )}
      >
        {data?.map((row, key) => (
          <BannerCard
            banner={row?.image ?? "/images/placeholder.svg"}
            title={row?.title}
            path={row?.path}
            isActive={currentSlide === key}
            key={"homeBanner" + key}
          />
        ))}
      </Slick>
    </div>
  );
};

export default Banner;
