import Section from "@/components/ui/section";
import React from "react";
import Image from "next/image";
export default function About() {
  const data = [
    {
      id: 1,
      image: "/images/noun-handmade-5385318.svg",
      title: "Hand Crafted Lustre Glassworks",
      description:
        "Boutique brand specializing in meticulously designed products by skilled artisans",
    },
    {
      id: 2,
      image: "/images/noun-creativity-6994813.svg",
      title: "Futuristic Creative  Artistry",
      description:
        "A blend of forward-thinking design and imaginative craftsmanship.",
    },
    {
      id: 3,
      image: "/images/noun-text-divider-2585126.svg",
      title: "Where Art Meets Elegance ",
      description: "Luxury that exudes creativity and timeless elegance.",
    },
  ];
  return (
    <Section className="bg-darkBeige" variant="flat">
      <div className="grid md:grid-cols-3 grid-cols-1 auto-rows-auto lg:gap-20 gap-8 w-full mt-6">
        {data?.map((row, key) => (
          <Block key={"aboutBlock" + key} data={row} />
        ))}
      </div>
    </Section>
  );
}
const Block = ({ data }) => {
  const { title, image, description } = data;
  return (
    <div className="w-full flex items-start md:gap-6 gap-4">
      <div className="lg:w-40 lg:h-40 md:w-32 md:h-32 h-24 w-24 relative shrink-0">
        <Image
          src={image}
          fill
          alt={title}
          className="object-contain object-center w-full h-full"
          sizes="(max-width: 768px) 400px, (max-width: 1200px) 400px, 400px"
        />
      </div>
      <div className="flex flex-col md:gap-3 gap-2 w-full">
        <p className="lg:text-2xl md:text-xl text-lg text-darkGrey font-extrabold leading-none">
          {title}
        </p>
        <p className="text-darkGrey !leading-tight md:text-base text-sm">
          {description}
        </p>
      </div>
    </div>
  );
};
